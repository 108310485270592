import styled from 'styled-components';
import FB from './img/facebook.svg';
import Twitter from './img/twitter.svg';
import LI from './img/linkedin.svg';
import Link from './img/link.svg';

export const FacebookIcon = styled(FB)`
  width: 24px;
  height: 24px;
`;

export const TwitterIcon = styled(Twitter)`
  width: 24px;
  height: 24px;
`;

export const LinkedInIcon = styled(LI)`
  width: 24px;
  height: 24px;
`;

export const LinkIcon = styled(Link)`
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;
