import React from 'react';
import styled from 'styled-components';

interface Props {
  fullName: string;
  avatar?: string;
  bio?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
`;

const AvatarImage = styled.img`
  margin-right: 1rem;
  border-radius: 100%;
  width: 50px;
`;

const AuthorBio = ({ fullName, avatar, bio }: Props) => {
  return (
    <Wrapper>
      {!!avatar && <AvatarImage src={avatar} />}
      <p>
        Written by <strong>{fullName}</strong>. {bio}
      </p>
    </Wrapper>
  );
};

export default AuthorBio;
