import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import PageLayout from '../components/PageLayout';
import SEO from '../components/SEO';
import AuthorBio from '../components/AuthorBio';
import ShareButtons from '../components/ShareButtons';

interface Props {
  data: {
    authors: {
      edges: [
        {
          node?: {
            id: string;
            fullName: string;
            avatar?: string;
            bio?: string;
          };
        }
      ];
    };
    markdownRemark: any;
  };
  pageContext: {
    previous: any;
    next: any;
    ogImageSrc?: string;
  };
}

const TitleHeader = styled.h1`
  margin-top: 1.75rem;
  margin-bottom: 0;
`;

const DateParagraph = styled.p`
  color: #222;
  font-weight: 400;
  display: block;
  margin-bottom: 1.75rem;

  font-size: 0.9rem;
  line-height: 1.6rem;

  @media (min-width: 500px) {
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
`;

const TOCSection = styled.section`
  font-weight: 400;
  color: #222;
  font-size: 0.9rem;
  line-height: 1.6rem;
  border: 1px solid #cccccc;
  padding: 0 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .language-text {
    color: #dd4a68;
  }

  li {
    margin: 0.25rem 0;
  }

  li p {
    margin: 0;
  }

  @media (min-width: 500px) {
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
`;

const ContentSection = styled.section`
  font-weight: 400;
  color: #222;
  font-size: 0.9rem;
  line-height: 1.6rem;
  padding-bottom: 1rem;

  .language-text {
    color: #dd4a68;
  }

  .gatsby-code-title {
    display: block;
    position: relative;
    background: #f5f2f0;
    width: 100%;
    top: 10px;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
  }

  .gatsby-code-title span {
    display: inline;
    position: relative;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    color: #eee;
    background: #777;
    border-top-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    padding: 3px;
    top: 1px;
  }

  li {
    margin: 0.25rem 0;
  }

  li p {
    margin: 0;
  }

  blockquote {
    border-left: 3px solid #aaa;
    padding-left: 1rem;
    color: #666;
  }

  @media (min-width: 500px) {
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
`;

const Footer = styled.footer`
  border-top: 1px solid #ccc;
  display: flex;
  padding: 1rem 0;
  flex-direction: column-reverse;

  @media (min-width: 500px) {
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;

const BioWrapper = styled.div`
  flex: 1;
  min-width: 50%;
`;

const CategoryList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: #222;
    font-size: 1.25rem;
    padding-left: 1rem;
  }
`;

const PrevNextList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  padding: 1rem 0 0 0;

  li:first-child {
    text-align: left;
    padding-right: 0.5rem;
  }

  li:last-child {
    text-align: right;
    padding-left: 0.5rem;
  }
`;

const PostTemplate = ({ data, pageContext }: Props) => {
  const post = data.markdownRemark;
  const { previous, next, ogImageSrc } = pageContext;

  const author = data.authors.edges?.find(
    ({ node }) => node?.id === post.frontmatter.author
  )?.node;

  return (
    <PageLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        ogImageSrc={ogImageSrc}
      />
      <article>
        <header>
          <TitleHeader>{post.frontmatter.title}</TitleHeader>
          <DateParagraph>
            {post.frontmatter.date}&nbsp;&nbsp;•&nbsp;&nbsp;
            {`${post.timeToRead} min read`}
          </DateParagraph>
        </header>
        {Boolean(post.frontmatter.showTOC && post.tableOfContents) && (
          <TOCSection>
            <h1 style={{ paddingBottom: 0, marginBottom: 0 }}>
              Table of Contents
            </h1>
            <div dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
          </TOCSection>
        )}
        <ContentSection dangerouslySetInnerHTML={{ __html: post.html }} />
        <ShareButtons
          url={'https://hellorealworld.com' + post.fields.slug}
          title={post.frontmatter.title}
          categories={post.frontmatter.categories || []}
        />
        <Footer>
          <BioWrapper>
            {author && (
              <AuthorBio
                fullName={author.fullName}
                avatar={author.avatar}
                bio={author.bio}
              />
            )}
          </BioWrapper>
          <CategoryList>
            {post.frontmatter.categories &&
              post.frontmatter.categories.map((category: string) => (
                <li key={category}>{category}</li>
              ))}
          </CategoryList>
        </Footer>
      </article>

      <nav>
        <PrevNextList>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </PrevNextList>
      </nav>
    </PageLayout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    authors: allAuthorsJson {
      edges {
        node {
          id
          fullName
          avatar
          bio
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      timeToRead
      html
      tableOfContents(absolute: false)
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        categories
        showTOC
      }
    }
  }
`;
