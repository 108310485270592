import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export default (category: string, action?: string, label?: string) => {
  console.log('trackEvent:', category, action, label);

  trackCustomEvent({
    category,
    action: action || '',
    label,
  });
};
