import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  LinkIcon,
} from './ShareIcons';
import { ACCENT_COLOR } from '../../theme';
import trackEvent from '../../utils/trackEvent';

interface Props {
  url: string;
  title: string;
  categories: string[];
}

const ShareList = styled.ul`
  padding: 0 0 0.5rem 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  li {
    margin-left: 1rem;
  }
`;

const CopySuccessMessage = styled.div`
  position: absolute;
  right: 10px;
  color: ${ACCENT_COLOR};
  font-size: 1rem;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
  transition: opacity 300ms ease-in-out;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.24);
  width: 200px;

  opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
`;

export default ({ url, title, categories }: Props) => {
  const [showCopySuccess, setShowCopySuccess] = React.useState(false);
  return (
    <ShareList>
      <li>
        <FacebookShareButton
          hashtag={categories[0] || undefined}
          url={url}
          onClick={() => trackEvent('Share', 'Facebook')}
        >
          <FacebookIcon />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton
          title={title}
          // hashtags={categories.map((category) => category.replace(/\#/g, ''))}
          related={['richardgirges']}
          url={url}
          onClick={() => trackEvent('Share', 'Twitter')}
        >
          <TwitterIcon />
        </TwitterShareButton>
      </li>
      <li>
        <LinkedinShareButton
          title={title}
          source="hellorealworld.com"
          url={url}
          onClick={() => trackEvent('Share', 'LinkedIn')}
        >
          <LinkedInIcon />
        </LinkedinShareButton>
      </li>
      <li style={{ position: 'relative', overflow: 'visible' }}>
        <CopyToClipboard
          text={url}
          onCopy={(_: any, result: boolean) => {
            if (result) {
              setShowCopySuccess(true);
              setTimeout(() => {
                if (setShowCopySuccess) {
                  setShowCopySuccess(false);
                }
              }, 3000);
              trackEvent('Share', 'Copy Link', 'Success');
            } else {
              trackEvent('Share', 'Copy Link', 'Failed');
            }
          }}
          title="Copy link"
        >
          <LinkIcon />
        </CopyToClipboard>
        <CopySuccessMessage show={showCopySuccess}>
          Link has been copied to clipboard!
        </CopySuccessMessage>
      </li>
    </ShareList>
  );
};
